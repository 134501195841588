main {
  overflow-x: hidden;
}

.circle {
  animation: rotate 1s linear infinite;
  animation-play-state: paused;
  animation-delay: calc(var(--scroll) * -10s);
  animation-iteration-count: infinite;
  animation-fill-mode: both;
}

.rotate-icon {
  transform: rotateY(180deg) rotateZ(180deg);
}

.circle-center {
  animation: rotate0 1s linear infinite;
  animation-play-state: paused;
  animation-delay: calc(var(--scroll) * -10s);
  animation-iteration-count: infinite;
  animation-fill-mode: both;
}

.circle-scroll-container {
  overflow-x: hidden;
}

.circle-loader rect {
  fill: none;
}

.circle-loader #lottie,
.circle-loader .lf-player-container {
  width: min(140vh, 140vw);
  height: min(140vh, 140vw);
}

@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}
@keyframes rotate0 {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(-180deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}

.circle-scroll-container::-webkit-scrollbar {
  display: none;
}
.circle-scroll-container::-webkit-scrollbar-thumb {
  display: none;
}

@media screen and (max-width: 768px) {
  .rotate-icon {
    transform: rotateY(180deg) rotateZ(90deg);
  }
}
